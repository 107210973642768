import React from 'react';
const User = ({ width = '40', className = '', height = '40', viewBox='0 0 40 40', idA='svg-user-a', idB='svg-user-b' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter id={idA} width="336.2%" height="336.2%" x="-118.1%" y="-118.1%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="2.973"/>
      </filter>
      <filter id={idB} width="216%" height="629%" x="-58%" y="-264.5%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="2.973"/>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">

      <g fillRule="nonzero" stroke="#FF9D9D" strokeLinecap="round" strokeWidth="2" opacity=".5" transform="translate(11 11)">
        <circle cx="8.55" cy="4.2" r="4.2" filter={"url(#" + idA + ")"}/>
        <path d="M17.1 17.25C15 15 11.85 13.5 8.55 13.5c-3.3 0-6.45 1.35-8.55 3.75" filter={"url(#" + idB + ")"}/>
      </g>
      <g fillRule="nonzero">
        <path fill="#EB2E2C" d="M19.55 23.5c3.485 0 6.925 1.544 9.281 4.068a1 1 0 01-1.462 1.364c-1.983-2.125-4.897-3.432-7.819-3.432-3.043 0-5.907 1.248-7.797 3.409a1 1 0 01-1.506-1.318C12.525 24.99 15.94 23.5 19.55 23.5zm0-13.5a5.2 5.2 0 110 10.4 5.2 5.2 0 010-10.4zm0 2a3.2 3.2 0 100 6.4 3.2 3.2 0 000-6.4z"/>
        <path fill="#FF6363" d="M19.55 24c3.344 0 6.653 1.485 8.916 3.909a.5.5 0 11-.732.682C25.658 26.367 22.613 25 19.55 25c-3.185 0-6.187 1.309-8.174 3.58a.5.5 0 11-.752-.66c2.18-2.49 5.458-3.92 8.926-3.92zm0-13.5a4.7 4.7 0 110 9.4 4.7 4.7 0 010-9.4zm0 1a3.7 3.7 0 100 7.4 3.7 3.7 0 000-7.4z"/>
        <path fill="#FF9D9D" d="M19.55 24.25c3.274 0 6.517 1.455 8.733 3.83a.25.25 0 01-.366.34c-2.123-2.274-5.234-3.67-8.367-3.67-3.256 0-6.327 1.339-8.362 3.665a.25.25 0 01-.376-.33c2.132-2.436 5.34-3.835 8.738-3.835zm0-13.5a4.45 4.45 0 110 8.9 4.45 4.45 0 010-8.9zm0 .5a3.95 3.95 0 100 7.9 3.95 3.95 0 000-7.9z"/>
      </g>
    </g>
  </svg>;

  export default User;