import React from 'react';
const Comment = ({ width = '49', className = '', height = '45', viewBox='0 0 49 45' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="svg-comment-b" d="M32.167.038H5.127C2.3.038 0 2.313 0 5.11v16.365c0 2.79 2.29 5.06 5.11 5.07v7.426l10.79-7.426h16.267c2.827 0 5.128-2.275 5.128-5.07V5.109c0-2.796-2.3-5.07-5.128-5.07zm2.942 21.436c0 1.604-1.32 2.909-2.942 2.909H15.215l-7.92 5.45v-5.45H5.126c-1.622 0-2.942-1.305-2.942-2.91V5.11c0-1.605 1.32-2.91 2.942-2.91h27.04c1.623 0 2.942 1.305 2.942 2.91v16.365z"/>
      <filter id="svg-comment-a" width="153.6%" height="161.6%" x="-26.8%" y="-29.5%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/>
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter2"/>
        <feOffset in="shadowSpreadOuter2" result="shadowOffsetOuter2"/>
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
        </feMerge>
      </filter>
      <path id="svg-comment-d" d="M9.982 7.674h17.33v2.161H9.983z"/>
      <filter id="svg-comment-c" width="215.4%" height="1025.5%" x="-57.7%" y="-462.7%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/>
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter2"/>
        <feOffset in="shadowSpreadOuter2" result="shadowOffsetOuter2"/>
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
        </feMerge>
      </filter>
      <path id="svg-comment-f" d="M9.982 12.284h17.33v2.161H9.983z"/>
      <filter id="svg-comment-e" width="215.4%" height="1025.5%" x="-57.7%" y="-462.7%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/>
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter2"/>
        <feOffset in="shadowSpreadOuter2" result="shadowOffsetOuter2"/>
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
        </feMerge>
      </filter>
      <path id="svg-comment-h" d="M9.982 16.895h17.33v2.16H9.983z"/>
      <filter id="svg-comment-g" width="215.4%" height="1025.5%" x="-57.7%" y="-462.7%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/>
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter2"/>
        <feOffset in="shadowSpreadOuter2" result="shadowOffsetOuter2"/>
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="matrix(-1 0 0 1 43 6)">
        <use fill="#000" filter="url(#svg-comment-a)" href="#svg-comment-b"/>
        <use fill="#FFCDCD" href="#svg-comment-b"/>
      </g>
      <g transform="matrix(-1 0 0 1 43 6)">
        <use fill="#000" filter="url(#svg-comment-c)" href="#svg-comment-d"/>
        <use fill="#FFCDCD" href="#svg-comment-d"/>
      </g>
      <g transform="matrix(-1 0 0 1 43 6)">
        <use fill="#000" filter="url(#svg-comment-e)" href="#svg-comment-f"/>
        <use fill="#FFCDCD" href="#svg-comment-f"/>
      </g>
      <g transform="matrix(-1 0 0 1 43 6)">
        <use fill="#000" filter="url(#svg-comment-g)" href="#svg-comment-h"/>
        <use fill="#FFCDCD" href="#svg-comment-h"/>
      </g>
    </g>
  </svg>;

  export default Comment;